@use 'sass:map';
@import 'react-calendar/dist/Calendar.css';

$theme-colors: (
	'primary': #007bff,
	'secondary': #2699fb,
	'success': #0ae2b9,
	'danger': #d70707,
	'red-light': #ff516d,
	'negative-red': #FF516D,
	'warning': #fdcd0e,
	'info': #17a2b8,
	'light': #f8f9fa,
	'grey-nav': #f1f1f1,
	'grey-header': #b9c0c8,
	'grey-image-box': #e9ecef,
	'grey-border': #d7dce2,
	'grey-card': #eff1f3,
	'grey-column': #f8f9f9,
	'grey-empty': #e7eaee,
	'grey-subtitle': #a2a2a2,
	'dark': #383c42,
	'dark-50': rgba(0, 0, 0, 0.5),
	'dark-30': rgba(0, 0, 0, 0.3),
	'red-50': rgba(255, 0, 41, 0.6),
	'white': white,
	'grey': #6c757d,
	'greyBorder': #ced4da,
	'blueBorder': #80bdff,
	'dropdownText': #0091ff,
	'black': #000000,
	'gradient-blue': #0091ff,
	'gradient-green': #00f1d0,
	'light-green': rgba(77, 230, 209, 0.1),
	'disable': #b9c0c8,
	'light-grey': #a9a8ac,
	'muted': #6c757d,
	'grey-dropdown': #424654,
	'lighter-light-grey': #e5e5e5,
	'brand-green': #00f1d0,
);

$background-dropdown-mobile: linear-gradient(
	270deg,
	rgba(80, 87, 106, 1) 0%,
	rgba(68, 74, 87, 1) 100%
);

// Color pickers

// Colors
$pickers-colors: (
	'blue': #3392ff,
	'green': #36a9a2,
	'gray': #444959,
	'purple': #7000ff,
	'red': #d70707,
	'yellow': #ff7a00,
	'white': #fff,
);

// Gradients for color pickers
$blue-bg: linear-gradient(to right, #0077ff, #0077ffb3) !important;
$green-bg: linear-gradient(to right, #36a9a2, #42cf85) !important;
$gray-bg: linear-gradient(to right, #444959, #50576acc) !important;
$purple-bg: linear-gradient(to right, #7000ff, #7000ffb3) !important;
$red-bg: linear-gradient(to right, #d70707, #d70707b3) !important;
$yellow-bg: linear-gradient(to right, #ff7a00, #ff7a00b3) !important;

@mixin color-picker-bg($color) {
	background: $color;
}

.color-picker-bg-blue {
	@include color-picker-bg($blue-bg);
}

.color-picker-bg-green {
	@include color-picker-bg($green-bg);
}

.color-picker-bg-gray {
	@include color-picker-bg($gray-bg);
}

.color-picker-bg-purple {
	@include color-picker-bg($purple-bg);
}

.color-picker-bg-red {
	@include color-picker-bg($red-bg);
}

.color-picker-bg-yellow {
	@include color-picker-bg($yellow-bg);
}

/*****                         *****/

$primary: #007bff;
$secondary: #2699fb;
$gradient_blue: #0091ff;
$gradient_green: #00f1d0;
$white: #fff;
$black: #000;

$enable-negative-margins: true;

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-bolder: bolder;

$line-height-sm: 1.25;
$line-height-base: 1.5;
$line-height-lg: 2;

$input-padding-x: 10px;
$input-padding-y: 5px;
$input-focus-box-shadow: 0 0rem 0.25rem rgba($primary, 0.3);
$form-select-focus-box-shadow: 0 0rem 0.25rem rgba($primary, 0.3);
$form-select-padding-x: 10px;
$form-select-padding-y: 5px;

$font-size-base: 1rem;
$font-family-base: 'Montserrat', sans-serif;

$line-height-base: 1.5;
$line-height-sm: 1.25;
$line-height-lg: 2;

$box-shadow: 0 0.5rem 1rem rgba($black, 0.15);
$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075);
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175);
$box-shadow-dropdown: 1px 1px 3px 3px rgba(0, 0, 0, 0.4);
$box-shadow-white-lg: 0 1rem 3rem rgba($white, 0.175);

$border-width: 1px;
$border-widths: (
	1: 1px,
	'1-5': 1.5px,
	2: 2px,
	3: 3px,
	4: 4px,
	5: 5px,
);

$border-color: #dee2e6;

$border-radius: 0.25rem;
$border-radius-sm: 0.2rem;
$border-radius-lg: 0.3rem;
$border-radius-pill: 50rem;

$position-values: (
	0: 0,
	3: 3%,
	50: 50%,
	100: 100%,
);

$spacer: 1rem;
$spacers: (
	0: 0,
	'0-5': $spacer * 0.125,
	1: $spacer * 0.25,
	'1-5': $spacer * 0.375,
	2: $spacer * 0.5,
	'2-5': $spacer * 0.75,
	3: $spacer,
	'3-5': $spacer * 1.25,
	4: $spacer * 1.5,
	'4-5': $spacer * 1.875,
	5: $spacer * 3,
	6: $spacer * 4,
	8: $spacer * 6,
);

$utilities: (
	'font-weight': (
		property: font-weight,
		class: fw,
		values: (
			light: $font-weight-light,
			lighter: $font-weight-lighter,
			normal: $font-weight-normal,
			semibold: $font-weight-semibold,
			bold: $font-weight-bold,
			bolder: $font-weight-bolder,
		),
	),
	'background-color': (
		property: background-color,
		class: bg,
		values:
			map-merge(
				$theme-colors,
				(
					'white': $white,
					'transparent': transparent,
				)
			),
	),
	'color': (
		property: color,
		class: text,
		values:
			map-merge(
				$theme-colors,
				(
					'white': $white,
					'black-50': rgba($black, 0.5),
					'white-50': rgba($white, 0.5),
					'reset': inherit,
				)
			),
	),
	'margin': (
		responsive: true,
		property: margin,
		class: m,
		values:
			map-merge(
				$spacers,
				(
					auto: auto,
				)
			),
	),
	'padding-start': (
		responsive: true,
		property: padding-left,
		class: ps,
		values:
			map-merge(
				$spacers,
				(
					'2-5': 0.875 * $spacer,
				)
			),
	),
	'padding': (
		responsive: true,
		property: padding,
		class: p,
		values:
			map-merge(
				$spacers,
				(
					'2-5': 0.875 * $spacer,
					'4-5': 2.25 * $spacer,
				)
			),
	),
	'padding-bottom':(
		responsive: true,
		property: padding-bottom,
		class: pb,
		values:
			map-merge(
				$spacers,
				(
					'2-5': 0.875 * $spacer,
					'4-5': 2.25 * $spacer,
				)
			),
	),
	'padding-top':(
		responsive: true,
		property: padding-top,
		class: pt,
		values:
			map-merge(
				$spacers,
				(
					'2-5': 0.875 * $spacer,
					'4-5': 2.25 * $spacer,
				)
			),
	),
	'border': (
		property: border,
		values: (
			null: $border-width solid $border-color,
			2: $border-width solid $border-color,
			0: 0,
		),
	),
	'border-top': (
		property: border-top,
		values: (
			null: $border-width solid $border-color,
			0: 0,
			1: 1px,
			10: 10px,
			30: 30px,
		),
	),
	'border-end': (
		property: border-right,
		class: border-end,
		values: (
			null: $border-width solid $border-color,
			0: 0,
			10: 10px,
			30: 30px,
		),
	),
	'border-bottom': (
		property: border-bottom,
		values: (
			null: $border-width solid $border-color,
			0: 0,
			10: 10px,
			30: 30px,
		),
	),
	'border-start': (
		property: border-left,
		class: border-start,
		values: (
			null: $border-width solid $border-color,
			0: 0,
			10: 10px,
			30: 30px,
		),
	),
	'border-width': (
		property: border-width,
		class: border,
		values: $border-widths,
	),
	'border-color': (
		property: border-color,
		class: border,
		values: map-merge($theme-colors, $pickers-colors),
	),
	'margin-x': (
		responsive: true,
		property: margin-right margin-left,
		class: mx,
		values:
			map-merge(
				$spacers,
				(
					auto: auto,
				)
			),
	),
	'margin-y': (
		responsive: true,
		property: margin-top margin-bottom,
		class: my,
		values:
			map-merge(
				$spacers,
				(
					auto: auto,
				)
			),
	),
	'margin-top': (
		responsive: true,
		property: margin-top,
		class: mt,
		values:
			map-merge(
				$spacers,
				(
					auto: auto,
					'n24': -24px,
				)
			),
	),
	'margin-end': (
		responsive: true,
		property: margin-right,
		class: me,
		values:
			map-merge(
				$spacers,
				(
					auto: auto,
					25: 25.5 * $spacer,
					30: 30 * $spacer,
				)
			),
	),
	'margin-bottom': (
		responsive: true,
		property: margin-bottom,
		class: mb,
		values:
			map-merge(
				$spacers,
				(
					auto: auto,
				)
			),
	),
	'margin-start': (
		responsive: true,
		property: margin-left,
		class: ms,
		values:
			map-merge(
				$spacers,
				(
					auto: auto,
				)
			),
	),
	'line-height': (
		property: line-height,
		class: lh,
		values: (
			xs: 0.5,
			1: 1,
			sm: $line-height-sm,
			base: $line-height-base,
			lg: $line-height-lg,
			2: $font-size-base * 1.3,
			'2-3': $font-size-base * 1.5,
			3: $font-size-base * 1.925,
			4: $font-size-base * 2.5,
			5: $font-size-base * 3.175,
			6: $font-size-base * 3.75,
			7: $font-size-base * 4.425,
			8: $font-size-base * 5.05,
		),
	),
	'font-size': (
		rfs: true,
		property: font-size,
		class: fs,
		values: (
			1: $font-size-base * 2.5,
			2: $font-size-base * 2,
			3: $font-size-base * 1.75,
			4: $font-size-base * 1.5,
			5: $font-size-base * 1.25,
			'5-6': $font-size-base * 1.13,
			6: $font-size-base,
			'6-5': $font-size-base * 0.875,
			7: $font-size-base * 0.75,
			8: $font-size-base * 0.5,
			analysis: $font-size-base * 0.9,
		),
	),
	'width': (
		property: width,
		class: w,
		values: (
			25: 25%,
			50: 50%,
			75: 75%,
			100: 100%,
			auto: auto,
			1: $font-size-base * 0.625,
			2: $font-size-base * 1.3,
			3: $font-size-base * 1.925,
			4: $font-size-base * 2.5,
			5: $font-size-base * 3.175,
			6: $font-size-base * 3.8,
			7: $font-size-base * 4.425,
			8: $font-size-base * 5.05,
			// fill: {
			// 	-webkit-fill-available
			// 	-moz-fill-available
			// },,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
		),
	),
	'max-width': (
		property: max-width,
		class: mw,
		values: (
			100: 100%,
			75: 75%,
			50: 50%,
			25: 25%,
			10: 10%,
			fit: fit-content,
			60px: 60px,
			172px: 172px,
		),
	),
	'rounded-start': (
		property: border-bottom-left-radius border-top-left-radius,
		class: rounded-start,
		values: (
			null: $border-radius,
			0: 0,
			1: 1rem,
			10: 10px,
			30: 30px,
		),
	),
	'rounded-top': (
		property: border-top-left-radius border-top-right-radius,
		class: rounded-top,
		values: (
			null: $border-radius,
			0: 0,
			1: 1rem,
			10: 10px,
			15: 15px,
			30: 30px,
		),
	),
	'rounded-bottom': (
		property: border-bottom-right-radius border-bottom-left-radius,
		class: rounded-bottom,
		values: (
			null: $border-radius,
			0: 0,
			10: 10px,
			15: 15px,
			30: 30px,
		),
	),
	'rounded-gout': (
		property: border-bottom-left-radius border-top-left-radius border-top-right-radius,
		class: rounded-gout,
		values: (
			null: $border-radius,
			10: 10px,
			100: 100px,
		),
	),
	'start': (
		property: left,
		class: start,
		values: $position-values,
	),
	'min-width': (
		property: min-width,
		class: minw,
		values: (
			100: 100%,
			75: 75%,
			50: 50%,
			25: 25%,
			10: 10%,
			fit: fit-content,
			60px: 60px,
		),
	),
	'object-fit': (
		property: object-fit,
		class: of,
		values: (
			cover: cover,
			contain: contain,
		),
	),
	'z-index': (
		property: z-index,
		class: zi,
		values: (
			0,
			1,
			2,
			3,
			4,
			5,
			6,
			7,
			8,
			9,
			10,
			1000,
			1050,
			1300,
		),
	),
	'cursor': (
		property: cursor,
		class: csr,
		values: (
			pointer: pointer,
		),
	),
	'min-height': (
		property: min-height,
		class: mnh,
		values: (
			100: 100%,
			95: 95%,
			90: 90%,
			75: 75%,
			50: 50%,
			25: 25%,
			10: 10%,
			8: 8%,
			5: 5%,
			60px: 60px,
			40px: 40px,
		),
	),
	'opacity': (
		property: opacity,
		values: (
			0: 0,
			25: 0.25,
			50: 0.5,
			60: 0.6,
			70: 0.7,
			75: 0.75,
			100: 1,
		),
	),
	'shadow': (
		property: box-shadow,
		class: shadow,
		values: (
			null: $box-shadow,
			sm: $box-shadow-sm,
			lg: $box-shadow-lg,
			white-lg: $box-shadow-white-lg,
			dropdown: $box-shadow-dropdown,
			none: none,
		),
	),
	'filter': (
		property: filter,
		class: f,
		values: (
			drop-shadow: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.5)),
		),
	),
	'max-height': (
		property: max-height,
		class: mh,
		values: (
			100vh: 100vh,
			100: 100%,
			90: 90%,
			75: 75%,
			50: 50%,
			25: 25%,
			10: 10%,
			120px: 120px,
			68px: 68px,
			172px: 172px,
		),
	),
	'right': (
		property: right,
		class: end,
		values: (
			100: 100%,
			90: 90%,
			75: 75%,
			50: 50%,
			25: 25%,
			10: 10%,
			5: 5%,
			0: 0%,
		),
	),
	'top': (
		property: top,
		class: top,
		values: (
			100: 100%,
			90: 90%,
			75: 75%,
			50: 50%,
			25: 25%,
			10: 10%,
			5: 5%,
			0: 0%,
		),
	),
);

$top: (
	100: 100%,
	95: 95%,
	90: 90%,
	85: 85%,
	80: 80%,
	75: 75%,
	70: 70%,
	65: 65%,
	60: 60%,
	55: 55%,
	50: 50%,
	45: 45%,
	40: 40%,
	35: 35%,
	30: 30%,
	20: 20%,
	25: 25%,
	15: 15%,
	10: 10%,
	5: 5%,
	3: 3%,
	0: 0%,
	10px: $font-size-base * 0.625,
	20px: $font-size-base * 1.3,
	30px: $font-size-base * 1.925,
	40px: $font-size-base * 2.5,
	50px: $font-size-base * 3.175,
	60px: $font-size-base * 3.75,
	65px: $font-size-base * 4,
	70px: $font-size-base * 4.425,
	80px: $font-size-base * 5.05,
);

$bps: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 1024px,
	xl: 1280px,
	xxl: 1440px,
);

@each $bp, $value in $bps {
	// .mw-#{$bp} {
	// 	max-width: $value;
	// }

	@media screen and (min-width: #{$value}) {
		// FIRST LOOP

		.position-#{$bp}-absolute {
			position: absolute;
		}
		.position-#{$bp}-relative {
			position: relative;
		}

		.position-#{$bp}-fixed {
			position: fixed;
		}

		@each $key, $value in map_get(map-get($utilities, 'opacity'), 'values')
		{
			.opacity-#{$bp}-#{$key} {
				opacity: $value !important;
			}
		}

		@each $key, $value in map_get(map-get($utilities, 'rounded-top'), 'values')
		{
			.rounded-top-#{$bp}-#{$key} {
				border-top-left-radius: $value !important;
				border-top-right-radius: $value !important;
			}
		}

		@each $key,
			$value in map_get(map-get($utilities, 'rounded-bottom'), 'values')
		{
			.rounded-bottom-#{$bp}-#{$key} {
				border-bottom-left-radius: $value !important;
				border-bottom-right-radius: $value !important;
			}
		}

		@each $key,
			$value in map_get(map-get($utilities, 'rounded-gout'), 'values')
		{
			.rounded-gout-#{$bp}-#{$key} {
				border-radius: $value $value 0 $value !important;
			}
		}

		@each $key,
			$value in map_get(map-get($utilities, 'rounded-start'), 'values')
		{
			.rounded-start-#{$bp}-#{$key} {
				border-top-left-radius: $value !important;
				border-bottom-left-radius: $value !important;
			}
		}

		@each $key, $value in map_get(map-get($utilities, 'font-size'), 'values') {
			.fs-#{$bp}-#{$key} {
				font-size: $value !important;
			}
		}

		@each $key, $value in map_get(map-get($utilities, 'max-height'), 'values') {
			.bg-#{$bp}-#{$key} {
				max-height: $value !important;
			}
		}

		@each $key, $value in map_get(map_get($utilities, 'max-width'), 'value') {
			.mw-#{$bp}-#{$key} {
				max-width: $value !important;
			}
		}

		@each $key,
			$value in map_get(map-get($utilities, 'background-color'), 'values')
		{
			.bg-#{$bp}-#{$key} {
				background-color: $value !important;
			}
		}

		@each $key, $value in map_get(map-get($utilities, 'max-height'), 'values') {
			.mh-#{$bp}-#{$key} {
				max-height: $value !important;
			}
		}

		@each $key, $value in map_get(map-get($utilities, 'max-height'), 'values') {
			.bg-#{$bp}-#{$key} {
				max-height: $value !important;
			}
		}

		@each $key, $value in map_get(map-get($utilities, 'line-height'), 'values')
		{
			.lh-#{$bp}-#{$key} {
				line-height: $value !important;
			}
		}

		@each $key, $value in map_get(map_get($utilities, 'max-width'), 'value') {
			.mw-#{$bp}-#{$key} {
				max-width: $value !important;
			}
		}

		// SECOND LOOP
		@each $t_top, $t_value in $top {
			.top-#{$bp}-#{$t_top} {
				top: #{$t_value};
			}
			.wd-#{$bp}-#{$t_top} {
				width: $t_value !important;
			}
			.w-#{$bp}-#{$t_top} {
				width: $t_value !important;
			}
			.start-#{$bp}-#{$t_top} {
				left: #{$t_value};
			}
			.end-#{$bp}-#{$t_top} {
				right: #{$t_value};
			}
			.h-#{$bp}-#{$t_top} {
				height: #{$t_value};
			}
			.border-bottom-#{$bp}-#{$t_top} {
				border-bottom: #{$t_value};
			}
			.ps-#{$bp}-#{$t_top} {
				padding-left: #{$t_value};
			}
			.pb-#{$bp}-#{$t_top} {
				padding-bottom: #{$t_value};
			}
		}
	}
}

$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 1024px,
	xl: 1280px,
	xxl: 1440px,
);

@mixin gradient-x-three-colors(
	$start-color: $gradient_green,
	$mid-color: $gradient_blue,
	$color-stop: 80%,
	$end-color: $gradient_green
) {
	background-image: linear-gradient(
		to right,
		$start-color,
		$mid-color $color-stop,
		$end-color
	);
}

@import '../node_modules/bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

* {
	font-family: 'Montserrat', sans-serif;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='search']::-ms-clear {
	display: none;
	width: 0;
	height: 0;
}

input[type='search']::-ms-reveal {
	display: none;
	width: 0;
	height: 0;
}

/* clears the ‘X’ from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
	display: none;
}

.bg-navbar-gradient {
	@include gradient-x-three-colors(
		$gradient_green,
		$gradient_blue,
		80%,
		$gradient_green
	);
}

.w-max-content {
	width: max-content;
}
.navbar-header {
	font-size: 18px;
	font-weight: 500;
}

.table-fixed {
	table-layout: fixed;
}

.library-search {
	width: 100%;
	padding: 0 1rem;
}

.modal-dialog-centered {
	display: flex;
	align-items: center;
	min-height: calc(100% - 1rem);
	justify-content: center;
}

.modal-sm {
	min-width: 368px;
}

.modal-content {
	border-radius: 16px;

	.modal-header {
		border-bottom: 0;
	}
	.modal-body {
		overflow: auto;
	}

	@media (min-width: 768px) {
		min-width: 368px;
		max-width: 500px;
		min-height: 185px;
		max-height: 520px;
	}
}

input.search-input.form-control {
	font-size: 1rem;
}

.nav.nav-tabs {
	& .nav-item .nav-link {
		border-radius: 8px 8px 0 0;
	}
}

@media (min-width: 576px) {
	.sidebar-desktop {
		max-width: 70px !important;
		transition: all 0.1s linear;
		width: 70px !important;
		background-color: 'magenta' !important
		// border-radius: 0px;
	}
	.sidebar-desktop-active {
		width: 172px !important;
		max-width: 172px !important;
	}
}

.tooltip-inner {
	background-color: rgba($color: #000000, $alpha: 0.6);
}
input::placeholder {
	color: map-get($theme-colors, 'dark-30') !important;
}
.bs-tooltip-start .tooltip-arrow::before {
	border-left-color: #f8f9fa;
}

@media (min-width: 574px) {
	.sidebar-desktop-hover {
		&:hover {
			width: 172px;
			border-radius: 0px 24px 24px 0px;
		}
	}
}

@media (max-width: 576px) {
	.library-search {
		max-width: 342px;
		width: 100%;
		padding: 0;

		& input::placeholder {
			font-size: 0.975rem;
		}
	}
}
.transition {
	transition: all 0.5s linear;
}
.tab-content {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	align-content: stretch;
	height: 100%;
	& > .active {
		display: flex;
		flex-direction: column;
		align-content: stretch;
		align-items: stretch;
		height: 100%;
	}
}

input[type='date']:invalid::-webkit-datetime-edit {
	color: #999;
}

button:focus {
	outline: none !important;
	box-shadow: none !important;
}

.h-webkit-fill {
	height: -webkit-fill-available;
	height: -moz-fill-available;
}

.w-webkit-fill {
	width: -webkit-fill-available;
	width: -moz-fill-available;
}

/* Works on Firefox */
.beauty-scroll {
	overflow: hidden;
	scrollbar-width: thin;
	scrollbar-color: rgba(52, 58, 64, 0.5) rgba(229, 229, 229, 0.5);
}

/* Works on Chrome, Edge, and Safari */
.beauty-scroll::-webkit-scrollbar {
	overflow: hidden;
	width: 8px;
	height: 8px;
}

.beauty-scroll::-webkit-scrollbar-track {
	overflow: hidden;
	background: rgba(229, 229, 229, 0.9);
}

.beauty-scroll::-webkit-scrollbar-thumb {
	overflow: hidden;
	background-color: rgba(200, 200, 200, 1);
	border-radius: 20px;
	border: 3px solid rgba(200, 200, 200, 1);
}

.beauty-scroll:hover {
	overflow: auto;
}
.button-size {
	width: 0.5em;
}

.cancel-modal-position {
	margin-top: 30vh;
}

.button-hover:hover {
	background: #e9e9e9 !important;
}

.button-hover-primary:hover {
	color: white;
}

.reset-button {
	/*Reset's every elements apperance*/
	background: none repeat scroll 0 0 transparent;
	border: medium none;
	border-spacing: 0;
	background: none repeat scroll 0 0 transparent;
	color: #383c42;
	font-size: 16px;
	font-weight: normal;
	line-height: 1.42rem;
	list-style: none outside none;
	margin: 0;
	padding: 0;
	text-align: left;
	text-decoration: none;
	text-indent: 0;
}

.rotate-90 {
	transform: rotate(90deg);
}

.react-calendar__month-view__days__day--weekend {
	color: map-get($theme-colors, 'danger') !important;
}

ABBR {
	text-decoration: none !important;
	text-decoration-skip-ink: none;
}

.react-calendar__tile--now {
	background: #eee !important;
	background-image: initial;
	background-position-x: initial;
	background-position-y: initial;
	background-size: initial;
	background-repeat: initial;
	background-attachment: initial;
	background-origin: initial;
	background-clip: initial;
	font-weight: bold;
	color: black;
	background-color: #eee !important;
}

.react-calendar__navigation__arrow {
	font-size: $font-size-base * 2;
}

.modal-content {
	max-height: 100% !important;
}

.modal-dialog-centered {
	display: flex;
	min-height: calc(100% - 5rem);
	align-items: center;
	justify-content: center;
}

.tut-btn-margin-mobile {
	@media screen and (min-device-height: 768px) and (max-device-height: 820px) and (orientation: landscape) {
		margin: 0 8px 8px 0;
	}

	@media screen and (min-device-width: 768px) and (max-device-width: 820px) and (orientation: portrait) {
		margin: 0 8px 8px 0;
	}

	@media screen and (max-device-height: 542px) and (orientation: landscape) {
		margin-top: -24px;
	}

	@media screen and (max-device-width: 542px) and (orientation: portrait) {
		margin-top: -24px;
	}
}

.w-100-70px {
	@media (min-width: 576px) {
		width: calc(100% - 70px) !important;
	}
}

.w-100-172 {
	@media (min-width: 576px) {
		width: calc(100% - 172px) !important;
	}
}

.mw-sm-60px {
	@media (min-width: 576px) {
		max-width: 60px !important;
	}
}

.w-sm-60px {
	@media (min-width: 576px) {
		max-width: 60px !important;
	}
}

.bg-sm-dropdown-mobile {
	@media (max-width: 576px) {
		background: $background-dropdown-mobile !important;
	}
}

.height-reader-for-landscape-mobile {
	@media screen and (max-device-width: 1000px) and (orientation: landscape) {
		height: 100% !important;
	}
}

#container-navbar-dropdown > div > div {
	background: $background-dropdown-mobile;
	border-radius: 10px;

	@media (min-width: 576px) {
		background: map.get($theme-colors, 'grey-dropdown');
		margin-left: 0;
		border-radius: 0px 10px 10px 10px;
		border-color: map.get($theme-colors, 'grey-dropdown');
	}
}

#container-navbar-dropdown > div > a {
	padding: 0 !important;
}

#container-navbar-dropdown > div > a::after {
	content: none;
}

#container-navbar-dropdown > div {
	// border-radius: 10px;

	@media (max-width: 576px) {
		background: $background-dropdown-mobile !important;
		padding: 0 15px 0 15px;
	}
	padding: 10px 8px 10px 10px;
}

#container-navbar-dropdown > div > div {
	@media (max-width: 576px) {
		padding-top: 0;
		border-radius: 0 0 10px 10px;
		margin-top: 0;
		border-width: 0;
		width: 100%;
	}
}

.mt-mobile-160 {
	margin-top: 160px !important;
}

.picker.picker-dialog {
	z-index: 1200;
	position: absolute;
}

.picker.picker-dialog-bg {
	z-index: 1100;
	position: absolute;
}

.vh-sm-100 {
  @media (min-width: 576px) {
    height: 100vh;
  }
}

#credential_picker_container > iframe {
  max-width: 100%;
  width: 100% !important;
}

